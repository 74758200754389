// import external dependencies

// Import everything from autoload
import './autoload/_bootstrap.js'

Joomla = window.Joomla || {};

(function(Joomla, document) {
  'use strict';

  // Allow lazyloading for stylesheets
  document.addEventListener('DOMContentLoaded', function (event) {
    [].slice.call(document.head.querySelectorAll('link[rel="lazy-stylesheet"]'))
    .forEach(function($link){
      $link.rel = "stylesheet";
    });
  });

  jQuery(function($) {
    // Add click event handler to hamburger icon
    $('.hamburger').on('click' ,function() {
      $(this).toggleClass('is-active');
    });

  });

})(Joomla, document);